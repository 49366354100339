.container {
  padding: 2rem;
}

.title {
  margin-bottom: 2.5rem;
}

.section {
  margin-bottom: 1rem;
}

.subSection {
  background-color: white;
  margin-bottom: 3rem;
  border-radius: 1rem;
  box-shadow:
    rgba(14, 63, 126, 0.06) 0px 0px 0px 1px,
    rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 2px 2px -1px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px,
    rgba(42, 51, 70, 0.03) 0px 10px 10px -5px,
    rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
}

.subTopSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2.8rem;
  font-size: 1.8rem;
  font-weight: 600;
  color: #6e7175;
  border-bottom: 1px solid #dfe0e0;
}

.subBottomSection {
  padding: 2.5rem;
}



.select {
  width: 100%;
  padding: 0.8rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  border-color: #cdcdcd;
  border-radius: 0.3rem;
}

.input {
  width: 100%;
  padding: 0.8rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  border: 1px solid #cdcdcd;
  outline: none;
  border-radius: 0.3rem;
}

.inputGroup {
  display: flex;
  padding: 0 4rem;
  margin-top: 1rem;
}

.inputLabel {
  padding: 1rem;
  font-size: 1.3rem;
  min-width: 10rem;
  color: #6d7175;
}

.saveButton {
  padding: 0.4rem 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px -2px 0px inset;
}

.saveButton:hover {
  background-color: #45a049;
}

.bookmarkTitle {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.bookmarkGroup {
  margin-bottom: 2rem;
}

.labelGroup {
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f7f7f7;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  font-size: 1.3rem;
  color: #6d7175;
}

.repoLabel {
  /*margin: 1rem 0;*/
  display: block;
  font-size: 1.3rem;
  color: #6d7175;
  margin: 0 0 1.5rem 2rem;
}

.deleteButton {
  padding: 0.4rem 0.6rem;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: #F09D74;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px -2px 0px inset;
  margin-right: 0.6rem;
}

.deleteButton:hover {
  background-color: #EB763C;
}



@media (max-width: 980px) {
  .container {
    margin-top: 6rem;
  }
}