.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.issueListSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.issueListContainer {
  /*max-width: 80rem;*/
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.issueStatisticsSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 8rem;
}

.issueStatisticsTitle {
  font-size: 1.8rem;
  font-weight: bold;
}

.issueStatisticsSubTitle {
  font-size: 1.6rem;
  font-weight: 430;
  align-self: flex-start;
  margin-left: 2rem;
}

.issueStatisticsItems {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.issueStatisticsItem {
  flex: 1 1 45%;
  flex-direction: column;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 2rem;
}

.issueStatisticsItem img {
  width: 100%;
  max-width: 300px;
  height: auto;
}

/*@media (min-width: 600px) {*/
/*  .issueStatisticsItems {*/
/*    flex-direction: row;*/
/*  }*/

/*  .issueStatisticsItem {*/
/*    flex: 1 1 30%;*/
/*  }*/
/*}*/

@media (max-width: 1200px) {
  .issueListContainer {
    flex-direction: column;
  }
}

@media (max-width: 990px) {
  .container {
    height: calc(100vh - 19rem);
  }
}

@media (max-width: 980px) {
  .container {
    margin-top: 6rem;
    flex-direction: column;
    height: calc(100vh - 16rem);
  }

  .issueStatisticsItems {
    flex-direction: column;
  }

  .issueStatisticsItem {
    flex: 1 1 30%;
  }
}

@media (max-width: 990px) {
  .container {
    margin-top: 5rem;
  }
}
