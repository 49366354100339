.mobileContainer {
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 5rem;
  padding-bottom: 8rem;
}

.icon {
  width: 2.2rem;
  height: 2.2rem;
}

.title {
  display: flex;
  gap: 1rem;
  font-weight: 600;
  font-size: 1.8rem;
}

.wordcloudContainer {
  width: 100%;
}

.wordCloud {
  margin-top: 2rem;
  height: 30rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.graph {
  margin-top: 3rem;
}
