@font-face {
  font-family: 'WantedSans';
  src: url('./fonts/WantedSans-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'WantedSans';
  src: url('./fonts/WantedSans-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'WantedSans';
  src: url('./fonts/WantedSans-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* Apply the font-family to your styles */
.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 192rem;
  margin: auto;
  padding: 5rem;
}

/* 모바일 스타일 정의 */
.mobileApp {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-sizing: border-box;
}

@media (max-width: 990px) {
  .App {
    padding: 3rem;
  }
}

html {
  background: white;
  font-size: 62.5%;
  overflow: hidden;
}

body {
  /* 익스플로러 스크롤바 옵션 */
  -ms-overflow-style: none;
  /* 파이어폭스 스크롤바 옵션 */
  scrollbar-width: none;
  padding: 0;
  margin: 0;
  width: 100%;
  font-family: 'Wanted Sans Variable', sans-serif;
}

h2 {
  font-size: 3.2rem;
  font-weight: 700;
}

/*Colors and Styles (Default)*/
:root {
  --git1: #4dad5d;
  --git2: #218d49;
  --git3: #4eaf5d;
  --git4: #27a857;
  --git0: #e4fbe7;
  --light-grey: #f1f1f1;
  --bg-color: #f9f9f9;
}

* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  transtion: 0.3s ease;
}

.PageContainer {
  max-width: 192rem;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
