.main {
  padding: 2rem;
  box-sizing: border-box;
  width: 22rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

.topContainer {
  height: fit-content;
}

.logo {
  margin-top: 3rem;
  width: 15rem;
}

.tab {
  width: 17rem;
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.whiteRound {
  background-color: white;
  border-radius: 50%;
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home,
.commit,
.issue,
.setting,
.logout {
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem 1rem;
  border-radius: 3rem;
  color: #abb5ba;
  font-size: 1.4rem;
  font-weight: 700;
  text-decoration: none;
}

.homeIcon,
.commitIcon,
.issueIcon,
.settingIcon,
.logoutIcon {
  width: 2rem;
}

.commitIconOrange {
  width: 2rem;
  color: #eb763c;
}

.logoCat {
  width: 14rem;
}

.clicked {
  background-color: #eb763c;
  color: white;
}
