.bottomInner {
  width: 100%;
  max-width: 22rem;
  gap: 2.2rem;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 1rem;
  box-sizing: border-box;
  height: 7rem;
  border-radius: 1.6rem;
  box-shadow:
    rgba(0, 0, 0, 0.08) 0rem 0.625rem 0.9375rem -0.1875rem,
    rgba(0, 0, 0, 0.05) 0rem 0.25rem 0.375rem -0.125rem;
}

.innerImage {
  width: 5rem;
  height: 5rem;
}

.textTitle {
  font-weight: 600;
  font-size: 1.8rem;
}

.textBottom {
  font-size: 1.4rem;
  color: #8a97a4;
}

@media screen and (max-width: 768px) {
  .bottomInner {
    max-width: 19rem;
    padding: 1rem;
    height: 6rem;
  }

  .innerImage {
    width: 4rem;
    height: 4rem;
  }

  .textTitle {
    font-size: 1.5rem;
  }

  .textBottom {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 576px) {
  .bottomInner {
    max-width: 18rem;
    padding: 1rem;
    height: 6.3rem;
  }

  .innerImage {
    width: 3.5rem;
    height: 3.5rem;
  }

  .textTitle {
    font-size: 1.4rem;
  }

  .textBottom {
    font-size: 1.2rem;
  }
}

@media (max-width: 425px) {
  .bottomInner {
    max-width: 17rem;
  }
}
