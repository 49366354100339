.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 40rem;
  max-height: 28rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f5f5f5;
}

.header span {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 0.5rem;
  /*background-color: #4caf50; */
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.title {
  font-size: 1.3rem;
  font-weight: bold;
  color: #333333;
}

.issueList {
  flex: 1;
  width: 100%;
  overflow-y: auto;
  padding: 1rem;
  background-color: #f9f9f9;
}

.lottie {
  margin: 2rem auto;
}
