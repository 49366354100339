.mobileContainer {
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  /*gap: 4rem;*/
  padding-bottom: 15rem;
  margin-top: 5rem;
}


.icon {
  width: 2.2rem;
}

.title {
  display: flex;
  gap: 1rem;
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}

.repoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.subSection {
  display: flex;
  align-items: center;
  background-color: white;
  /*margin-top: 1rem;*/
  border-radius: 1rem;
  box-shadow:
    rgba(14, 63, 126, 0.06) 0px 0px 0px 1px,
    rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 2px 2px -1px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px,
    rgba(42, 51, 70, 0.03) 0px 10px 10px -5px,
    rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
  width: 90%;
}

.repoLabel {
  /*margin: 1rem 0;*/
  display: block;
  font-size: 1.3rem;
  color: #6d7175;
  margin: 0 0 1.5rem 1rem;
}

.saveButton {
  width: 60%;
  height: 3rem;
  font-size: 1.4rem;
  font-weight: 600;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px -2px 0px inset;
  margin-top: 2rem;
}

.saveButton:hover {
  background-color: #45a049;
}


.subBottomSection {
  padding: 2.5rem;
}

.inputGroup {
  display: flex;
  padding: 0 2rem;
  margin-top: 1rem;
  /*width: 100%;*/
}

.select {
  width: 100%;
  padding: 0.8rem;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  border-color: #cdcdcd;
  border-radius: 0.3rem;
}
