.mobileContainer {
    color: black;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding-bottom: 15rem;
    margin-top: 5rem;
}

.issueContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.issueListContiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}

.issueOpen {
    margin-bottom: 2rem;
}

.issueClosed {
    margin-top: 2rem;
}

.icon {
    width: 2.2rem;
}

.title {
    display: flex;
    gap: 1rem;
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.pieChartContainer {
    overflow-x: auto;
}
