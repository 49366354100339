.main {
  width: 100%;
  height: 100%;
  background: var(--bg-color);
}

.logo {
  width: 30rem;
  height: 15rem;
  object-fit: cover;
}

.container {
  margin: auto;
  width: 100rem;
  height: calc(100vh - 30rem);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20rem;
}

.logo2 {
  width: 39rem;
}

.signIn {
  width: 37rem;
}

.titleContainer {
  margin-bottom: 4rem;

}

.title {
  font-size: 3.5rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

.subTitle {
  font-size: 1.3rem;
  font-weight: 400;
  color: #b5b5b5;
  position: absolute;
  font-family: 'Poppins', sans-serif;
}

.loginBtn {
  margin-top: 3rem;
  background-color: var(--git3);
  width: 37rem;
  height: 6rem;
  border-radius: 0.8rem;
  font-size: 1.8rem;
  font-weight: 500;
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  /*box-shadow: 0px 4px 61px rgba(78, 171, 95, 0.41);*/
  box-shadow:
    rgba(78, 171, 95, 0.42) 0px 2px 6px 0px,
    rgba(78, 171, 95, 0.62) 0px 2px 20px 0px;
}

.other {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #b5b5b5;
  font-size: 1.6rem;
  margin-top: 5.5rem;
}

.loginIcon {
  display: flex;
  gap: 2rem;
}

.icon {
  width: 4rem;
  height: 4rem;
  margin-top: 4.5rem;
}

.githubIcon {
  width: 3rem;
}
