@font-face {
  font-family: 'WavvePADO-Regular';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2404@1.0/WavvePADO-Regular.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
}

.wordCloud {
  overflow: hidden;
  height: 30rem;
  width: 100%;
}
