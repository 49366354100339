@font-face {
  font-family: 'WavvePADO-Regular';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2404@1.0/WavvePADO-Regular.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
}

.wordCloud {
  overflow: hidden;
  height: 30rem;
  width: 100%;
}

@media (max-width: 1800px) {
  .wordCloud {
    width: 75rem;
  }
}

@media (max-width: 1440px) {
  .wordCloud {
    width: 53rem;
  }
}

@media (max-width: 1200px) {
  .wordCloud {
    width: 38rem;
  }
}

@media (max-width: 1024px) {
  .wordCloud {
    width: 30rem;
  }
}

@media (max-width: 990px) {
  .wordCloud {
    width: 45rem;
  }
}

@media (max-width: 768px) {
  .wordCloud {
    width: 33rem;
  }
}

@media (max-width: 600px) {
  .wordCloud {
    width: 25rem;
  }
}
