.mobileContainer {
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 5rem;
  padding-bottom: 8rem;
}

.comemonLogo {
  width: 10rem;
  margin-bottom: -5rem;
}

@media screen and (min-width: 769px) {
  .comemonLogo {
    display: none;
  }
}

.icon {
  width: 2.2rem;
}

.title {
  display: flex;
  gap: 1rem;
  font-weight: 600;
  font-size: 1.8rem;
}

.chartContainer {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
}

.issueContiner {
  margin-top: 3rem;
}
