.main {
  z-index: 100;
  max-width: 49.2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 2rem;
}

.search {
  height: 5rem;
  width: 100%;
  gap: 1rem;
  background-color: #dfe6ec;
  padding: 0 0.5rem 0 2rem;
  box-sizing: border-box;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchButton {
  width: fit-content;
  height: 4rem;
  border-radius: 50%;
}

.searchInput {
  flex: 1;
  background-color: transparent;
  border: 0;
}

.searchInput:focus {
  outline: none;
}

.searchIcon {
  right: 1rem;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
}

.searchButton {
  cursor: pointer;
}

.resultContainer {
  height: 30.8rem;
  position: absolute;
  margin-top: 5.8rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.innerContainer {
  width: 100%;
  height: 28rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.polygon {
  height: 0.8rem;
  width: 1.6rem;
}

.searchResults {
  width: 44rem;
  height: 30rem;
  background-color: white;
  border-radius: 1.3rem;
  box-shadow: 0px 6px 10px rgba(101, 101, 101, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 1.5rem 0 1.5rem;
  box-sizing: border-box;
  /* 익스플로러 스크롤바 옵션 */
  -ms-overflow-style: none;
  /* 파이어폭스 스크롤바 옵션 */
  scrollbar-width: none;
  overflow-y: auto;
}

.result {
  cursor: pointer;
  width: 100%;
  height: 6rem;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 2rem;
}

.result:hover {
  background-color: rgb(39, 168, 87, 0.5);
  color: white;

  .name,
  .date {
    color: white;
  }
}

.profile {
  width: 4rem;
  border-radius: 50%;
  aspect-ratio: 1;
}

.profileImage {
  width: 4rem;
  border-radius: 50%;
}

.line {
  margin: 1rem 0;
  width: 37rem;
  border: 0;
  height: 0.1rem;
  background-color: #eeeeee;
}

.commit {
  display: flex;
  flex-direction: column;
  width: 28rem;
}

.message {
  font-size: 1.3rem;
  font-weight: 700;
  color: black;
  text-decoration: none;
}

.commiter {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.name,
.date {
  font-size: 1.2rem;
  color: #7f8595;
}

.name {
  font-weight: 600;
}

.arrowIcon {
  width: 2.3rem;
  aspect-ratio: 1;
}

.innerContainer {
  margin-bottom: 2rem;
}

.line:last-child {
  display: none;
}

.result:last-child {
  margin-bottom: 2rem;
}

@media (max-width: 990px) {
  .main {
    margin-top: 8rem;
    max-width: none;
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
}
