@font-face {
  font-family: 'DungGeunMo';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/DungGeunMo.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  position: relative;
  border-radius: 1.2rem;
  /*background-color: black;*/
}

.logoContainer {
  width: 24rem;
  height: auto;
  margin-left: 2rem;
  margin-top: 0.2rem;
}

.logo {
  width: 12rem;
  height: auto;
}

.comemonsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  gap: 5rem;
  row-gap: 0.1rem;
}

.itemContainer {
  position: relative;
  width: 10rem;
  height: 16rem;
  padding: 1.1rem;
}

@media (max-width: 430px) {
  .itemContainer {
    width: 8rem;
    height: 13rem;
  }
}

.nameBox {
  position: absolute;
  bottom: 1.4rem;
  left: 50%;
  transform: translateX(-50%);
  width: 130%;
  height: 7rem;
  background-image: url('../assets/images/comemon/name.png');
  background-size: cover;
  background-repeat: round;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.8rem;
  color: black;
}

.comemonName {
  font-family: 'DungGeunMo', sans-serif;
  font-size: 1.9rem;
}

.comemonLv {
  color: #6d7175;
  font-size: 1.1rem;
  margin: 0;
  font-weight: bold;
}

.comemon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.emptyBox {
  display: none;
  height: 5em;
}

.loadingContainer {
  width: 100%;
  height: 18rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .logoContainer img {
    display: none;
  }

  .emptyBox {
    display: block;
  }
}
