.main {
  width: 100%;
  margin-top: 2rem;
  height: calc(100vh - 25rem);
  overflow-y: auto;
}

.innerContainer {
  height: fit-content;
  padding: 0 1rem 3rem;
}

.wordCloudContainer {
  margin-top: 2rem;
  /*background-color: white;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30rem;
  /*box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 8px -10px;*/
  /*overflow: hidden;*/
}

.timelineContainer {
  width: 100%;
}

.timelineImg {
  width: 100%;
}

.timeline {
  position: relative;
  top: -2rem;
}

.timelineImage {
  width: 100%;
  max-width: 70rem;
}

.bottomContainer {
  width: 100%;
  margin-top: 2rem;
  gap: 3%;
}

.contribution {
  width: 100%;
}

.graph {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem;
  box-sizing: border-box;
  background-color: white;
  border-radius: 2rem;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.rankContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  margin-top: 2rem;
}

.graphImage {
  width: 100%;
}

.ranking {
  width: 30rem;
  margin-top: 4rem;
}

@media (min-width: 885px) {
  .bottomContainer {
    flex-direction: column;
  }

  .ranking {
    width: 100%;
  }
}

@media (min-width: 991px) {
  .main {
    height: calc(100vh - 21rem);
  }

  .ranking {
    margin-top: 3rem;
    width: 100%;
  }

  .rankContainer {
    width: 100%;
    flex-direction: column;
  }

  .innerContainer {
    min-width: 20rem;
    width: 100%;
  }
}

@media (min-width: 1000px) {
  .bottomContainer {
    flex-direction: column;
  }
}

@media (min-width: 1220px) {
  .rankContainer {
    flex-direction: row;
  }
}

@media (min-width: 1440px) {
  .bottomContainer {
    display: flex;
    flex-direction: row;
  }

  .ranking {
    width: 35%;
    margin-top: 0;
  }

  .rankContainer {
    flex-direction: column;
  }
}

@media (min-width: 1920px) {
  .timelineImage {
    width: 60%;
    max-width: 144rem;
  }
}
