.container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 1.2rem;
  font-weight: 500;
  color: #333333;
}

.statusBar {
  max-width: 4rem;
  height: 4px;
  /*background-color: #4caf50;*/
  margin-top: 0.5rem;
  border-radius: 1rem;
}

.assignees {
  display: flex;
  margin-top: 0.5rem;
}

.assignee {
  width: 2rem;
  height: 2rem;
  background-color: #9e9e9e;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.open .header span,
.open .statusBar {
  background-color: #4caf50;
}

.closed .header span,
.closed .statusBar {
  background-color: #eb763c;
}
